<template>
    <div id="appCapsule">
        <div class="section wallet-card-section" style="padding-top:100px">
            <div class="card">
                <div class="card-body">
                    <form @submit.prevent="proceed">
                        <h3>Add a new wallet address</h3>
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="select4">Wallet</label>
                                <select class="form-control custom-select" id="select4" v-model="crypto_id">
                                    <option value="">Select a wallet</option>
                                    <option :value="crypt_asset[0]" v-for="(crypt_asset, idx) in wallets" :key="idx">
                                        {{crypt_asset[1]}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="phone4">Address</label>
                                <input type="text" v-model="new_address" class="form-control" id="phone4" placeholder="Type in the wallet address">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="row justify-content-center mt-3">
                            <div class="col-5">
                                <button class="btn btn-outline-primary" type="submit">
                                    <ion-icon name="add-circle-outline"></ion-icon> Save
                                </button>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "addWallet",
    data(){
        return {
            crypto_id: '',
            new_address: ''
        }
    },
    computed: {
        wallets(){
            return Object
                .entries(this.$globalFunc.cryptoList)
                .filter(crypto_asset=>!this.$globalFunc.cryptoExistInCoinbase(crypto_asset[0]))
        }
    },
    methods: {
        async proceed(){
            if(this.crypto_id && this.new_address.length > 10){
                this.$loader.show();
                let response = await this.$store.dispatch('wallet/addWalletAddress', {
                    cryptoID: this.crypto_id,
                    address: this.new_address
                });
                this.$loader.hide();
                if(response.status){
                    this.$store.dispatch('notification/display_noti', {
                        message: 'New wallet address added',
                        timeout: 1500
                    });
                    setTimeout(()=>{
                        this.$router.push({name: 'Wallet'})
                    }, 2000)
                }else {
                    return this.$store.dispatch('notification/display_noti', {
                        message: response.message,
                    });
                }
            }
        }
    }
}
</script>

<style scoped>

</style>