import { render, staticRenderFns } from "./addWallet.vue?vue&type=template&id=120bb82a&scoped=true&"
import script from "./addWallet.vue?vue&type=script&lang=js&"
export * from "./addWallet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "120bb82a",
  null
  
)

export default component.exports